/*
              _
   _   _ ____| |_
  | | | / _  |  _)
   \ V ( ( | | |__
    \_/ \_||_|\___)
*/
stFinancement.prototype.calculMensualiteVAT = function (apport, duree, kilometrage, optionsSouscrites) {
    this.apport = apport;
    this.duree = duree;
    this.kilometrage = kilometrage;

    if (this.dateImmatriculation === '' || this.dateImmatriculation === null) {
        var errorDescription = [];
        errorDescription['date_immat'] = "non_renseignee";
        return {"error": true, "value": "r", "description": errorDescription};
    }

    if (this.getAgeVehicule() > eightYears) {
        var errorDescription = [];
        errorDescription['age'] = ">";
        return {"error": true, "value": "r", "description": errorDescription};
    }

    if (eval(eligibiliteVehicule) === false) {
        return {"error": true, "value": "v"};
    }

    var eligibiliteVehicule = this.vxlvat.eligibiliteVehicule;
    eligibiliteVehicule = eligibiliteVehicule
        .replace('ageVehicule', this.getAgeVehicule())
        .replace('eightYears', eightYears)
        .replace(/genre/g, this.genre);

    var eligibiliteError = this.checkReglettesEligibilite('vxlvat');

    if (eligibiliteError.error === true) {
        return { "error": true, "value": "r", "description": eligibiliteError };
    }

    var taux = this.vxlvat['tauxDeBase'];

    var mensualiteBase = this.calculValeurMensualiteVAT(this.prixVehiculeTTC - apport, taux, this.duree, this.vxlvat.pourcentFraisDossier);
    var prixOptions = this.calculPrixOptions(optionsSouscrites, apport, 'VXLVAT');
    var tdf = this.vxlvat['tauxDebiteurFixe'];

    var montantTotalCredit = this.prixVehiculeTTC - apport + prixOptions;
    var montantMensualite = mensualiteBase + prixOptions;
    var montantMensualiteSansOption = mensualiteBase;

    var di = isNaN(this.calculVXLVATDI()) ? null : parseFloat(this.calculVXLVATDI());
    var taea = optionsSouscrites['DI'] === 1 ? this.calculTaea(montantTotalCredit, montantMensualite, di, this.duree) : 0;

    return {
        "error": false,
        "value": mensualiteBase + prixOptions,
        "sansOpt": mensualiteBase,
        "di" : di,
        "ic" : di,
        "garantie" : isNaN(this.calculGARANTIE()) ? null : parseFloat(this.calculGARANTIE()),
        "maintenance" :  isNaN(this.calculMAINTENANCE()) ? null : parseFloat(this.calculMAINTENANCE()),
        "taeg": this.vxlvat['taeg'],
        "taea": taea,
        "tdf": tdf,
        "prixOptions": prixOptions
    };
}
stFinancement.prototype.calculValeurMensualiteVAT = function (paramMontantFinance, paramTaux, paramDuree, pourcentFraisDossier) {
    var fraisDossier = parseFloat(((pourcentFraisDossier / 100) * paramMontantFinance).toFixed(2));

    // Montant frais de dossier = % frais de dossier * montant financé
    // VPM(Taux nominal/12;Durée;Montant Financé+(Montant frais de dossier*(1-Taux nominal/12)))
    // console.log('Taux nominal : ' + paramTaux);
    // console.log('Taux nominal / 12 : ' + paramTaux/12);
    // console.log('Durée : ' +  paramDuree);
    // console.log('Montant financé : ' +  paramMontantFinance);
    // console.log('Pourcentage frais dossier : ' +  pourcentFraisDossier);
    // console.log('Frais de dossier : ' +  fraisDossier);
    // console.log('Montant Financé+(Montant frais de dossier*(1-Taux nominal/12)) : ' + -(paramMontantFinance + (fraisDossier * (1 - paramTaux/12))));

    return this.vpm(paramTaux/12, paramDuree, -(paramMontantFinance + (fraisDossier * (1 - paramTaux/12))), 0, 0);
}

/*
              _
   _   _ ____| |_
  | | | / _  |  _)
   \ V ( ( | | |__
    \_/ \_||_|\___) PRO
*/
stFinancement.prototype.calculMensualitePROVAT = function (apport, duree, kilometrage, optionsSouscrites) {
    this.apport = apport;
    this.duree = duree;
    this.kilometrage = kilometrage;

    var eligibiliteVehicule = this.provat.eligibiliteVehicule;
    eligibiliteVehicule = eligibiliteVehicule
        .replace('ageVehicule', this.getAgeVehicule())
        .replace('eightYears', eightYears)
        .replace('avecTVA', this.avecTVA)
        .replace(/genre/g, this.genre)
    ;

    if (this.dateImmatriculation === '' || this.dateImmatriculation === null) {
        var errorDescription = [];
        errorDescription['date_immat'] = "non_renseignee";
        return {"error": true, "value": "r", "description": errorDescription};
    }

    if (this.getAgeVehicule() > eightYears) {
        var errorDescription = [];
        errorDescription['age'] = ">";
        return {"error": true, "value": "r", "description": errorDescription};
    }
    if (this.genre == 'VU' || this.genre == 'CTTE' || this.genre == 'DERIVE VP') {
        if (apport > (this.prixVehiculeTTC - 6001)) {
            var errorDescription = [];
            errorDescription['apport'] = ">";
            return {"error": true, "value": "r", "description": errorDescription};
        }
        if (duree > 60) {
            var errorDescription = [];
            errorDescription['duree'] = ">";
            return {"error": true, "value": "r", "description": errorDescription};
        }
    }

    if (eval(eligibiliteVehicule) === false) {
        return {"error": true, "value": "v"};
    }

    var eligibiliteError = this.checkReglettesEligibilite('provat');

    if (eligibiliteError.error === true) {
        return { "error": true, "value": "r", "description": eligibiliteError };
    }

    var taux = this.provat['tauxDeBase'];

    var mensualiteBase = this.calculValeurMensualiteVAT(this.prixVehiculeTTC - apport, taux, this.duree, this.vxlvat.pourcentFraisDossier);

    var prixOptions = this.calculPrixOptions(optionsSouscrites, apport, 'PROVAT');
    var tdf = this.provat['tauxDebiteurFixe'];

    var montantTotalCredit = this.prixVehiculeTTC - apport + prixOptions;
    var montantMensualite = mensualiteBase + prixOptions;
    var montantMensualiteSansOption = mensualiteBase;
    var di = isNaN(this.calculPROLOADI()) ? null : parseFloat(this.calculPROLOADI());
    var taea = optionsSouscrites['DI'] === 1 ? this.calculTaea(montantTotalCredit, montantMensualite, di, this.duree) : 0;

    return {
        "error": false,
        "value": mensualiteBase + prixOptions,
        "sansOpt": mensualiteBase,
        "di" : di,
        "ic" : isNaN(this.calculPROLOAIC()) ? null : parseFloat(this.calculPROLOAIC()),
        "garantie" : isNaN(this.calculGARANTIE()) ? null : parseFloat(this.calculGARANTIE()),
        "maintenance" : isNaN(this.calculMAINTENANCE()) ? null : parseFloat(this.calculMAINTENANCE()),
        "taeg": this.provat['taeg'],
        "taea": taea,
        "tdf": tdf,
        "prixOptions": prixOptions
    };
}

/*
   _
  | |
  | | ___   ____
  | |/ _ \ / _  |
  | | |_| ( ( | |
  |_|\___/ \_||_|
*/
stFinancement.prototype.calculMensualiteLOA = function (apport, duree, kilometrage, optionsSouscrites, vr = LOA_VR_BASE) {
    this.apport = apport;
    this.duree = duree;
    this.kilometrage = kilometrage;
    if (vr != LOA_VR_1 && vr != LOA_VR_10 && vr != LOA_VR_BASE) {
        vr = LOA_VR_BASE;
    }

    var eligibiliteVehicule = this.vxlloa.eligibiliteVehicule;
    eligibiliteVehicule = eligibiliteVehicule
        .replace('ageVehicule', this.getAgeVehicule())
        .replace('fiveYears', fiveYears)
        .replace('avecTVA', this.avecTVA)
        .replace(/genre/g, this.genre);

    if (this.dateImmatriculation === '' || this.dateImmatriculation === null) {
        var errorDescription = [];
        errorDescription['date_immat'] = "non_renseignee";
        return {"error": true, "value": "r", "description": errorDescription};
    }

    if (this.getAgeVehicule() > fiveYears) {
        var errorDescription = [];
        errorDescription['age'] = ">";
        return {"error": true, "value": "r", "description": errorDescription};
    }
    if (eval(eligibiliteVehicule) === false) {
        return {"error": true, "value": "v"};
    }

    var eligibiliteError = this.checkReglettesEligibilite('vxlloa');

    if (eligibiliteError.error === true) {
        return { "error": true, "value": "r", "description": eligibiliteError };
    }

    var taux = this.vxlloa.taux;
    var mensualiteBase = this.calculValeurMensualiteLOA(this.prixVehiculeTTC, this.apport, this.duree, taux, this.prixVehiculeTTC * vr, this.totalHT)
    var prixOptions = parseFloat(this.calculPrixOptions(optionsSouscrites, apport, 'VXLLOA'));
    var premierLoyer = parseFloat(this.apport > 0 ? this.apport : mensualiteBase);

    return {
        "error": false,
        "value": mensualiteBase + prixOptions,
        "sansOpt": mensualiteBase,
        "di" : isNaN(this.calculVXLLOADI()) ? null : parseFloat(this.calculVXLLOADI()),
        "ic" : isNaN(this.calculVXLLOAIC()) ? null : parseFloat(this.calculVXLLOAIC()),
        "garantie" : isNaN(this.calculGARANTIE()) ? null : parseFloat(this.calculGARANTIE()),
        "maintenance" : isNaN(this.calculMAINTENANCE()) ? null : parseFloat(this.calculMAINTENANCE()),
        "premierLoyer": premierLoyer,
        "vr": this.prixVehiculeTTC * vr,
        "pourcentVr": vr * 100,
        "premierLoyerAvecOpt": premierLoyer + prixOptions,
        "prixOptions": prixOptions
    };
}

/*
   _
  | |
  | | ___   ____
  | |/ _ \ / _  |
  | | |_| ( ( | |
  |_|\___/ \_||_| PRO
*/
stFinancement.prototype.calculMensualitePROLOA = function (apport, duree, kilometrage, optionsSouscrites, vr = LOA_VR_BASE, ht = this.genre == 'VU' || this.genre == 'CTTE' || this.genre == 'DERIVE VP') {
    this.apport = apport;
    this.duree = duree;
    this.kilometrage = kilometrage;

    var eligibiliteVehicule = this.proloa.eligibiliteVehicule;
    eligibiliteVehicule = eligibiliteVehicule
        .replace('ageVehicule', this.getAgeVehicule())
        .replace('fiveYears', fiveYears)
        .replace('avecTVA', this.avecTVA)
        .replace(/genre/g, this.genre);

    if (this.dateImmatriculation === '' || this.dateImmatriculation === null) {
        var errorDescription = [];
        errorDescription['date_immat'] = "non_renseignee";
        return {"error": true, "value": "r", "description": errorDescription};
    }

    if (this.getAgeVehicule() > fiveYears) {
        var errorDescription = [];
        errorDescription['age'] = ">";
        return {"error": true, "value": "r", "description": errorDescription};
    }
    if (eval(eligibiliteVehicule) === false) {
        return {"error": true, "value": "v"};
    }

    var eligibiliteError = this.checkReglettesEligibilite('proloa');

    if (eligibiliteError.error === true) {
        return { "error": true, "value": "r", "description": eligibiliteError };
    }

    var taux = this.proloa.taux;
    var mensualiteBase = this.calculValeurMensualiteLOA(this.prixVehiculeTTC, this.apport, this.duree, taux, this.prixVehiculeTTC * vr, this.totalHT);
    var prixOptions = parseFloat(this.calculPrixOptions(optionsSouscrites, apport, 'PROLOA'));
    var premierLoyer = parseFloat(this.apport > 0 ? this.apport : mensualiteBase);

    return {
        "error": false,
        "value": ht ? (mensualiteBase / (1+TAUX_TVA)) + prixOptions  : mensualiteBase + prixOptions,
        "sansOpt": ht ? mensualiteBase / (1+TAUX_TVA) : mensualiteBase,
        "di" : isNaN(this.calculPROLOADI())? null : parseFloat(this.calculPROLOADI()),
        "ic" : isNaN(this.calculPROLOAIC())? null : parseFloat(this.calculPROLOAIC()),
        "garantie" : isNaN(this.calculGARANTIE())? null : parseFloat(this.calculGARANTIE()),
        "maintenance" : isNaN(this.calculMAINTENANCE())? null : parseFloat(this.calculMAINTENANCE()),
        "premierLoyer": ht ? premierLoyer / (1+TAUX_TVA) : premierLoyer,
        "vr": ht ? (this.prixVehiculeTTC * vr) / (1+TAUX_TVA) : this.prixVehiculeTTC * vr,
        "pourcentVr": vr * 100,
        "premierLoyerAvecOpt": ht ? (premierLoyer / (1+TAUX_TVA)) + prixOptions : premierLoyer + prixOptions,
        "ht" : ht,
        "prixOptions": prixOptions,
    };
}
stFinancement.prototype.calculMensualitePROLOAB = function (apport, duree, kilometrage, optionsSouscrites, vrSaisie = null,    ht = this.genre == 'VU' || this.genre == 'CTTE' || this.genre == 'DERIVE VP') {
    this.apport = apport;
    this.duree = duree;
    this.kilometrage = kilometrage;
    this.kilometrageTotal = (this.duree / 12) * this.kilometrage;

    // Eligibilité véhicule
    var eligibiliteVehicule = this.proloab.eligibiliteVehicule;

    eligibiliteVehicule = eligibiliteVehicule
        .replace('ageVehicule', this.getAgeVehicule())
        .replace('fiveYears', fiveYears)
        .replace('avecTVA', this.avecTVA)
        .replace(/genre/g, this.genre);

    if (this.dateImmatriculation === '' || this.dateImmatriculation === null) {
        var errorDescription = [];
        errorDescription['date_immat'] = "non_renseignee";
        return {"error": true, "value": "r", "description": errorDescription};
    }


    if (this.getAgeVehicule() > fiveYears) {
        var errorDescription = [];
        errorDescription['age'] = ">";
        return {"error": true, "value": "r", "description": errorDescription};
    }

    if (eval(eligibiliteVehicule) === false
        || !(this.puissanceFiscale && this.energie && this.elibibiliteDirectDrive == 1)
    ) {
        return {"error": true, "value": "v"};
    }

    var eligibiliteError = this.checkReglettesEligibilite('proloab');

    if (eligibiliteError.error === true) {

        return { "error": true, "value": "r", "description": eligibiliteError };
    }

    var taux = this.proloab.tauxBase;

    var vrMin = 0;
    var vrMax = 0;


    var donneesVr = this.getVRLoaBallonDirectDrive();
    var vr = donneesVr['vr'];
    vrMin = Math.ceil(donneesVr['vrMin']);
    vrMax = Math.ceil(donneesVr['vrMax']);

    if (vrSaisie != null) {
        vrSaisie = parseFloat(vrSaisie);
        if (!isNaN(vrSaisie) && vrSaisie >= vrMin && vrSaisie <= vrMax) {
            vr = vrSaisie;
        }
    }


    if (vr <= 0)  {
        return {"error": true, "value": "r", "description": true};
    }

    var mensualiteBase = this.calculValeurMensualiteLOA(this.prixVehiculeTTC, this.apport, this.duree, taux, vr, this.totalHT)
    var prixOptions = parseFloat(this.calculPrixOptions(optionsSouscrites, apport, 'PROLOAB'));
    var premierLoyer = parseFloat(this.apport > 0 ? this.apport : mensualiteBase);

    var coeffKm = 0;
    coeffKm = this.proloab.tauxDirectDrive['>15000'].energie[this.energie].puissanceFiscale[this.puissanceFiscale > 11 ? 11 : this.puissanceFiscale];

    return {
        "error": false,
        "value":  ht ? (mensualiteBase / (1+TAUX_TVA)) + prixOptions  : mensualiteBase + prixOptions,
        "sansOpt": ht ? mensualiteBase / (1+TAUX_TVA) : mensualiteBase,
        "di" : isNaN(this.calculPROLOABDI())? null : parseFloat(this.calculPROLOABDI()),
        "ic" : isNaN(this.calculPROLOABIC())? null : parseFloat(this.calculPROLOABIC()),
        "garantie" : isNaN(this.calculGARANTIE())? null : parseFloat(this.calculGARANTIE()),
        "maintenance" : isNaN(this.calculMAINTENANCE())? null : parseFloat(this.calculMAINTENANCE()),
        "premierLoyer":  ht ? premierLoyer / (1+TAUX_TVA) : premierLoyer,
        "vr": ht ? vr / (1+TAUX_TVA) : vr,
        "pourcentVr": (vr / this.prixVehiculeTTC) * 100,
        "coefKm": coeffKm,
        "premierLoyerAvecOpt": ht ? (premierLoyer / (1+TAUX_TVA)) + prixOptions : premierLoyer + prixOptions,
        "prixOptions": prixOptions,
        "vrMin" : vrMin,
        "vrMax" : vrMax,
        "ht" : ht,
    };
}
stFinancement.prototype.calculValeurMensualiteLOA = function (totalTTC, apport, duree, taux, vr, totalHT, depotGarantie) {
    if (typeof depotGarantie == 'undefined') {
        depotGarantie = LOA_GARANTIE_BASE;
    }

    if (totalHT === 0 || totalHT === null) {
        totalHT = totalTTC / 1.2;
    }

    if ((apport + depotGarantie) > 0) {
        var tmp = this.va((taux/12), 1, 0, -((vr/1.2) - depotGarantie), 0);
    } else {
        var tmp = vr/1.2;
    }

    if ((apport + depotGarantie) > 0) {
        return this.vpm(taux/12, (duree-1), -((totalHT) - (apport/1.2) - depotGarantie), tmp, 0) * 1.2;
    } else {
        return this.vpm(taux/12, duree, -((totalHT) - (apport/1.2)), tmp, 1) * 1.2;
    }
}

/* _                _           _ _
  | |              | |         | | |
  | | ___   __ _   | |__   __ _| | | ___  _ __
  | |/ _ \ / _` |  | '_ \ / _` | | |/ _ \| '_ \
  | | (_) | (_| |  | |_) | (_| | | | (_) | | | |
  |_|\___/ \__,_|  |_.__/ \__,_|_|_|\___/|_| |_|
*/
stFinancement.prototype.calculMensualiteLOAB = function (apport, duree, kilometrage, optionsSouscrites, repriseMarchand = 0, vrSaisie = null) {

    this.apport = apport;
    this.duree = duree;
    this.kilometrage = kilometrage;
    this.repriseMarchand = repriseMarchand;
    this.kilometrageTotal = (this.duree / 12) * this.kilometrage;

    // Eligibilité véhicule
    var eligibiliteVehicule = this.vxlloab.eligibiliteVehicule;

    eligibiliteVehicule = eligibiliteVehicule
        .replace('ageVehicule', this.getAgeVehicule())
        .replace('fiveYears', fiveYears)
        .replace('avecTVA', this.avecTVA)
        .replace(/genre/g, this.genre)
        .replace(/repriseMarchand/g, repriseMarchand);

    if (this.dateImmatriculation === '' || this.dateImmatriculation === null) {
        var errorDescription = [];
        errorDescription['date_immat'] = "non_renseignee";
        return {"error": true, "value": "r", "description": errorDescription};
    }


    if (this.getAgeVehicule() > fiveYears) {
        var errorDescription = [];
        errorDescription['age'] = ">";
        return {"error": true, "value": "r", "description": errorDescription};
    }

    if (eval(eligibiliteVehicule) === false
        || !(this.puissanceFiscale && this.energie && ((this.eligibiliteLoab && this.coeffsVR && repriseMarchand == 0) || (this.elibibiliteDirectDrive == 1  && repriseMarchand == 1)))
    ) {
        return {"error": true, "value": "v"};
    }

    var eligibiliteError = this.checkReglettesEligibilite('vxlloab', repriseMarchand);

    if (eligibiliteError.error === true) {

        return { "error": true, "value": "r", "description": eligibiliteError };
    }

    var taux = this.vxlloab.tauxBase;

    var vrMin = 0;
    var vrMax = 0;

    if (repriseMarchand == 1) {
        var donneesVr = this.getVRLoaBallonDirectDrive();
        var vr = donneesVr['vr'];
        vrMin = Math.ceil(donneesVr['vrMin']);
        vrMax = Math.ceil(donneesVr['vrMax']);

        if (vrSaisie != null) {
            vrSaisie = parseFloat(vrSaisie);
            if (!isNaN(vrSaisie) && vrSaisie >= vrMin && vrSaisie <= vrMax) {
                vr = vrSaisie;
            }
        }
    } else {
        var vr = this.getVRLoaBallon();
    }

    if (vr <= 0)  {
        return {"error": true, "value": "r", "description": true};
    }

    var mensualiteBase = this.calculValeurMensualiteLOA(this.prixVehiculeTTC, this.apport, this.duree, taux, vr, this.totalHT)
    var prixOptions = parseFloat(this.calculPrixOptions(optionsSouscrites, apport, 'VXLLOAB'));
    var premierLoyer = parseFloat(this.apport > 0 ? this.apport : mensualiteBase);

    var coeffKm = 0;
    if (repriseMarchand == 1) {
        coeffKm = this.vxlloab.tauxDirectDrive['>15000'].energie[this.energie].puissanceFiscale[this.puissanceFiscale > 11 ? 11 : this.puissanceFiscale];
    } else {
        coeffKm = this.vxlloab.taux[this.kilometrage <= 20000 ? '<=20000' : '>20000'].energie[this.energie].puissanceFiscale[this.puissanceFiscale > 11 ? 11 : this.puissanceFiscale];
    }


    return {
        "error": false,
        "value": mensualiteBase + prixOptions,
        "sansOpt": mensualiteBase,
        "di" : isNaN(this.calculVXLLOABDI()) ? null : parseFloat(this.calculVXLLOABDI()),
        "ic" : isNaN(this.calculVXLLOABIC()) ? null : parseFloat(this.calculVXLLOABIC()),
        "garantie" : isNaN(this.calculGARANTIE()) ? null : parseFloat(this.calculGARANTIE()),
        "maintenance" : isNaN(this.calculMAINTENANCE()) ? null : parseFloat(this.calculMAINTENANCE()),
        "premierLoyer": premierLoyer,
        "vr": vr,
        "pourcentVr": vr / this.prixVehiculeTTC * 100,
        "coefKm": coeffKm,
        "premierLoyerAvecOpt": premierLoyer + prixOptions,
        "prixOptions": prixOptions,
        "vrMin" : vrMin,
        "vrMax" : vrMax,
    };
}
stFinancement.prototype.getVRLoaBallon = function () {
    var puissanceFiscaleVR = this.puissanceFiscale > 11 ? 11 : this.puissanceFiscale;
    var coeffKilometre = this.vxlloab.taux[this.kilometrage <= 20000 ? '<=20000' : '>20000'].energie[this.energie].puissanceFiscale[puissanceFiscaleVR];
    var coeffVR = this.coeffsVR[this.duree];

    var prixKilometresSupp = 0;
    if (this.kilometrage > 10000) {
        var kmTranche1 = 0;
        var kmTranche2 = 0;
        var kmTranche3 = 0;

        if (this.kilometrage < 15000) {
            kmTranche1 = this.kilometrage - 10000;
        } else if (this.kilometrage < 20000) {
            kmTranche1 = 5000;
            kmTranche2 = this.kilometrage - 15000;
        } else {
            kmTranche1 = 5000;
            kmTranche2 = 5000;
            kmTranche3 = this.kilometrage - 20000;
        }

        var taux1 = this.vxlloab.taux['<=15000'];
        var taux2 = this.vxlloab.taux['<=20000'].energie[this.energie].puissanceFiscale[puissanceFiscaleVR];
        var taux3 = this.vxlloab.taux['>20000'].energie[this.energie].puissanceFiscale[puissanceFiscaleVR];

        prixKilometresSupp = ((kmTranche1 * taux1) + (kmTranche2 * taux2) + (kmTranche3 * taux3)) * (this.duree / 12);
    }

    var vr = (this.prixConstructeurTTC * (coeffVR / 100)) - prixKilometresSupp;
    if (vr < this.prixVehiculeTTC * 0.15) {
        return (this.prixVehiculeTTC * 0.15);
    } else {

        return vr;
    }
}
stFinancement.prototype.getVRLoaBallonDirectDrive = function () {

    // On calcule la VR par défaut (sur 25 mois)
    var puissanceFiscaleVR = this.puissanceFiscale > 11 ? 11 : this.puissanceFiscale;
    var pourcentageVr = 55;

    var vrMin = 0;
    var vrMax = 0;
    if (this.duree >= 25 && this.duree < 37) {
        vrMin = this.prixVehiculeTTCHorsFrais * 0.4;
        vrMax = this.prixVehiculeTTCHorsFrais * 0.63;
    }

    if (this.duree >= 37 && this.duree < 43) {
        vrMin = this.prixVehiculeTTCHorsFrais * 0.3;
        vrMax = this.prixVehiculeTTCHorsFrais * 0.58;
    }

    if (this.duree >= 43 && this.duree < 48) {
        vrMin = this.prixVehiculeTTCHorsFrais * 0.2;
        vrMax = this.prixVehiculeTTCHorsFrais * 0.48;
    }

    if (this.duree >= 48 && this.duree < 60) {
        vrMin = this.prixVehiculeTTCHorsFrais * 0.15;
        vrMax = this.prixVehiculeTTCHorsFrais * 0.44;
    }

    if (this.duree >= 60) {
        vrMin = this.prixVehiculeTTCHorsFrais * 0.15;
        vrMax = this.prixVehiculeTTCHorsFrais * 0.40;
    }

    //dépréciation annuelle
    var depreciationMensuelleTranche1 = ((((this.duree > 36 ? 36 : this.duree) - 25) *  0.5833) );
    var depreciationMensuelleTranche2 = ((((this.duree > 36 ? this.duree : 36) - 36) *  0.8333) );

    var prixKilometresSupp = 0;
    if (this.kilometrage > 10000) {
        var kmTranche1 = 0;
        var kmTranche2 = 0;

        if (this.kilometrage < 15000) {
            kmTranche1 = this.kilometrage - 10000;
        } else {
            kmTranche1 = 5000;
            kmTranche2 = this.kilometrage - 15000;
        }

        var taux1 = this.vxlloab.tauxDirectDrive['<=15000'];
        var taux2 = this.vxlloab.tauxDirectDrive['>15000'].energie[this.energie].puissanceFiscale[puissanceFiscaleVR];


        prixKilometresSupp = ((kmTranche1 * taux1) + (kmTranche2 * taux2) ) * (this.duree / 12);
    }

    var vr = (((pourcentageVr - depreciationMensuelleTranche1 - depreciationMensuelleTranche2)/100) * this.prixVehiculeTTCHorsFrais) - prixKilometresSupp;

    if (vr < vrMin) {
        vr =  vrMin;
    }

    if (vr > vrMax) {
        vr =  vrMax;
    }

    return {"vr" : vr, "vrMin" : vrMin, 'vrMax' : vrMax};
}